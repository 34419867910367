import { Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@material-ui/core';
import React, { Fragment } from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { addressTypes } from '../../utils/servicios-medicos/constants';
import { useStyles } from '../../utils/servicios-medicos/styles';
import { useGlobalStyles } from '../../utils/styles';

const DomiciliosUmfs = () => {
  const theme = useTheme();
  const classes = useStyles();
  const globalClasses = useGlobalStyles();

  return (
    <Layout>
      <SEO title="Domicilios Umfs" />

      <div className={globalClasses.container}>
        <Header title="Domicilios de centros de atención y de unidades de medicina familiar"></Header>

        <Grid container spacing={3} className={classes.info}>
          {addressTypes.map((type, index) => (
            <Fragment key={index}>
              <Grid item xs={12}>
                <Typography variant="body1">{type.descripcion}</Typography>
              </Grid>
              <Grid item xs={12}>
                <TableContainer>
                  <Table size="small" className={classes.table}>
                    <TableHead>
                      <TableRow>
                        {type.columns.map((column, index) => <TableCell key={index} component="th">{column}</TableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {type.rows.map((row, index) => (
                        <Fragment key={index}>
                          <TableRow>
                            {row.rows ? (
                              <TableCell component="td" scope="row" colSpan={3}>
                                <Grid container style={{ textAlign: 'center' }}>
                                  <Grid item xs={12}>
                                    <Typography variant="h5" component="p">{row.title} {row.subtitle}</Typography>
                                  </Grid>
                                  {row.phone ? (
                                    <Grid item xs={12}>
                                      <Typography variant="h5" component="p">
                                        TELÉFONO:
                                        <a href={`tel:${row.phone}`} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main, marginLeft: 5 }}>{row.phone}</a>

                                        {row.extension ? <span style={{ marginLeft: 8 }}>Extensión: {row.extension}</span> : null}
                                      </Typography>
                                    </Grid>
                                  ) : null}
                                  {row.email ? (
                                    <Grid item xs={12}>
                                      <Typography variant="h5" component="p">
                                        CORREO:
                                        <a href={`mailto:${row.email}`} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main, marginLeft: 5 }}>{row.email}</a>
                                      </Typography>
                                    </Grid>
                                  ) : null}
                                </Grid>
                              </TableCell>
                            ) : (
                              <>
                                <TableCell component="td" scope="row">{row.no}</TableCell>
                                <TableCell component="td" scope="row">
                                  <Typography variant="body1">{row.title}</Typography>
                                  {row.phone ? (
                                    <a href={`tel:${row.phone}`} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main }}>
                                      <Typography variant="subtitle2" component="p">{row.phone}</Typography>
                                    </a>
                                  ) : null}
                                </TableCell>
                                <TableCell component="td" scope="row">{row.description}</TableCell>
                              </>
                            )}
                          </TableRow>
                          {row.rows?.map((row, index) => (
                            <TableRow key={index}>
                              <TableCell component="td" scope="row">{row.no}</TableCell>
                              <TableCell component="td" scope="row">
                                <Typography variant="body1">{row.title}</Typography>
                                {row.phone ? (
                                  <a href={`tel:${row.phone}`} target="_blank" rel="noopener noreferrer" style={{ color: theme.palette.primary.main }}>
                                    <Typography variant="subtitle2" component="p">{row.phone}</Typography>
                                  </a>
                                ) : null}
                              </TableCell>
                              <TableCell component="td" scope="row">{row.description}</TableCell>
                            </TableRow>
                          ))}
                        </Fragment>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </div>
    </Layout>
  );
};

export default DomiciliosUmfs;